import React, { useEffect } from 'react'
import { Routes, Route, Outlet, useNavigate } from 'react-router-dom'
import ErrorPage from 'components/pages/ErrorPage'
import LandingPage from 'components/pages/LandingPage'
import LoginPage from 'components/pages/LoginPage'
import UsersPage from 'components/pages/UsersPage'
import EditUser from 'components/pages/UsersPage/EditUser'
import AccountsPage from 'components/pages/AccountsPage'
import RequesReportersPage from 'components/pages/RequestReportersPage'
import NewTemplatePage from 'components/pages/TemplatesPage/NewTemplateBuilder/NewTemplatePage'
import ShowTemplate from 'components/pages/TemplatesPage/ShowTemplate'
import { useProfile } from 'components/context/ProfileContext'
import EditTemplatePage from 'components/pages/TemplatesPage/EditTemplatePage/EditTemplatePage'
import ComposerPage from 'components/pages/ComposerPage'
import TemplateDetails from 'components/pages/TemplatesPage/NewTemplateBuilder/NewTemplatePage/TemplateDetails'
import TemplateBuilderPage from 'components/pages/TemplatesPage/NewTemplateBuilder/TemplateBuilderPage'
import PersonalizedDetails from 'components/pages/TemplatesPage/NewTemplateBuilder/TemplateBuilderPage/PersonalizedDetails'
import QueueStatusPage from 'components/pages/QueueStatusPage'
import RequestPage from 'components/pages/RequestPage'
import ComposerPageV2 from 'components/pages/ComposerPage/ComposerPageV2'

export const AppRoutes = () => {
  const { profile, isLoading } = useProfile()
  const location = useNavigate()
  const ProtectedRoute = ({ isAllowed, redirectPath = '/login', children }) => {
    useEffect(() => {
      if (!isAllowed && !isLoading) {
        if(redirectPath === '/login'){
          const currentPath = window.location.pathname
          location({
            pathname: redirectPath,
            search: `?redirect=${currentPath}`,
          })
        } else location(redirectPath)
      }
    }, [isAllowed, redirectPath])

    if (!isAllowed) {
      return null
    }

    return children ? children : <Outlet />
  }
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route element={<ProtectedRoute isAllowed={!!profile} />}>
        <Route exact path="/" element={<LandingPage />} />
        <Route path="/request-reporter" element={<RequesReportersPage />} />
        <Route path="/profile" element={<EditUser />} />
        <Route path="/new-template" element={<NewTemplatePage />} />
        <Route path="/templates/:id" element={<ShowTemplate />} />
        <Route path="/templates/new" element={<NewTemplatePage />} />
        <Route path="/templates/details" element={<TemplateDetails />} />
        <Route path="/templates/:id/edit" element={<EditTemplatePage />} />
        <Route path="/templates/:id/builder" element={<TemplateBuilderPage />} />
        <Route path="/templates/:id/composer" element={<ComposerPage />} />
        <Route path="/templates/:id/composer2" element={<ComposerPageV2 />} />
        <Route path="/templates/:id/variables" element={<PersonalizedDetails />} />
        <Route path="/templates/:id/requests" element={<RequestPage />} />
      </Route>
      <Route element={<ProtectedRoute isAllowed={!!profile && profile.role === 'admin'} redirectPath='/' />}>
        <Route path="/users" element={<UsersPage />} />
        <Route path="/accounts" element={<AccountsPage />} />
        <Route path="/queue-status" element={<QueueStatusPage />} />
      </Route>
    </Routes>
  )
}

export default AppRoutes