const QueueEndpoint = (axios) => {
  const status = async () => {
    const { data } = await axios.get('/web/queue_status')
    return data
  }

  return {
    status,
  }
}

export default QueueEndpoint