import React from 'react'
import { BannerProvider } from 'components/context/BannerContext'
import { TemplateProvider } from 'components/context/TemplateContext'
import { ProfileProvider } from 'components/context/ProfileContext'
import { ImageComposerProvider } from 'components/context/ImageComposerContext'
import { SearchProvider } from 'components/context/SearchContext'
import { AccountProvider } from 'components/context/AccountContext'
import { VariableProvider } from '../context/VariableContext'

const ApplicationProvider = ({ children }) => (
  <AccountProvider>
    <TemplateProvider>
      <ProfileProvider>
        <SearchProvider>
          <BannerProvider>
            <VariableProvider>
              <ImageComposerProvider>
                { children }
              </ImageComposerProvider>
            </VariableProvider>
          </BannerProvider>
        </SearchProvider>
      </ProfileProvider>
    </TemplateProvider>
  </AccountProvider>
)

export default ApplicationProvider