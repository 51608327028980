import React, { useCallback, useEffect, useState } from 'react'
import { Button, Divider, List, InputNumber, Popconfirm } from 'antd'
import { useBannerActions } from 'components/context/BannerContext'
import { useTemplateActions } from 'components/context/TemplateContext'
import { useNavigate } from 'react-router-dom'
import FileDetails from './FileDetails'
import GifComposerForm  from './GifComposerForm'
import apiClient from 'components/lib/api/apiClient'
import { ReactSortable } from 'react-sortablejs'

const GifDetails = ({template}) => {
  const [files, setFiles] = useState(null)
  const [searchResults, setSearchResults] = useState([])
  const [order, setOrder] = useState([])
  const [delayData, setDelayData] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const {setBannerMessage, setBannerError} = useBannerActions()
  const { updateTemplate } = useTemplateActions()
  const location = useNavigate()

  const addToArray = ({id, name}) => {
    const nameExists = order.some((item) => item.name === name)
    if(!nameExists){
      if(id !== '-'){
        setDelayData((prevData) => ({
          ...prevData,
          [id]: 125,
        }))
      }
      const currentList = [...order, {id, name}]
      setOrder(currentList)
    } else setBannerError('Item already exists')
  }

  const createPart = async (payload) => {
    try {
      const response = await apiClient.parts.createPart(template.id, payload)
      if(response.success){
        setBannerMessage('Part has been created!')
        const templateResponse = await apiClient.templates.getTemplate(template.id)
        if(templateResponse.success){
            updateTemplate(template.id, templateResponse.template)
            location(`/templates/${template.id}`)
        }
      }
    } catch (error) {
      setBannerError('Failed to create part!')
    }
  }

  const updatePart = async (payload) => {
    try {
      const response = await apiClient.parts.updatePart(template.id, template.parts[0].id, payload)
      if(response.success){
        setBannerMessage('Gif has been updated!')
        const templateResponse = await apiClient.templates.getTemplate(template.id)
        if(templateResponse.success){
            updateTemplate(template.id, templateResponse.template)
            location(`/templates/${template.id}`)
        }
      }
    } catch (error) {
      setBannerError('Failed to update part!')
    }
  }

  const handleSubmit = async () => {
    const gifName = order.find(obj => obj.id === '-')?.name || 'no.gif'
    const payload = {
      name: gifName,
      options: {
        build_order: order.map((item) => item.id),
        delay: delayData,
      },
    }

    if(template.parts.length > 0) updatePart(payload)
    else createPart(payload)
  }

  const onChange = (e, templateId) => {
    setDelayData((prevData) => ({
      ...prevData,
      [templateId]: e,
    }))
  }

  const createNewComposer = async () => {
    const temp = searchResults[0]
    const {config} = temp.options
    // options: {"config"=>{"extension"=>".PNG", "canvas"=>{"dimensions"=>"400x400"}}
    const payload = {
      canvas: config['canvas'],
      destination: config['extension'],
      options: {
        dimensions: config['canvas']['dimensions']
      }
    }
    const newTemplate = {
      account_id: temp.account_id,
      parent_id: template.id,
      required_variables: [],
      tag: `${template.tag}-${searchResults.length}`,
      kind: 'image',
      source: 'composer',
      version: '1',
      status: 'Active',
      name: `${template.name}-${searchResults.length}`,
    }
    try {
      const response = await apiClient.templates.createTemplate(newTemplate)
      if (response.success) {
        const partsResponse = await apiClient.parts.createPart(response.template.id, payload)
        if(partsResponse.success){
          setBannerMessage('Composer created')
          location(`/templates/${response.template.id}/composer?parentId=${template.id}`)
        }
      } else {
        setBannerError(response.errors[0])
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getChildren = useCallback(async () => {
    try {
      const response = await apiClient.templates.getChildren(template.id)
      if(response.success){
        setSearchResults(response.templates)
      }
    } catch (error) {
      setBannerError('Failed to get children')
    }
  }, [setBannerError, template.id])

  useEffect(() => {
    if(!template || !searchResults.length) return
    const { options } = template
    let localDelay = {}
    const buildOrder = options.config?.build_order?.map((id) => {
      if (id === '-') return {id, name: files[0]}
      const foundTemplate = searchResults.find((temp) => temp.id === id)
      localDelay = {
        ...localDelay,
        [id]: options?.config?.delay[id],
      }
      return {
        id,
        name: foundTemplate.name
      }
    }).filter(Boolean)
    setDelayData(localDelay)
    setOrder(buildOrder || [])
  }, [searchResults, template, files])

  useEffect(() => {
    getChildren()
  }, [getChildren])

  return (
    <>
      <div>Gif Details</div>
      <Divider />
        {searchResults.length === 0 && <Button onClick={() => setOpenModal(true)}>Create New Composer</Button> }
        {searchResults.length > 0 && (
        <>
          <Popconfirm
            title="Are you sure you want to create a new composer?"
            onConfirm={createNewComposer}
            okText="Yes"
            cancelText="No"
          >
            <Button onClick={(e) => e.stopPropagation()}>Create New Composer</Button>
          </Popconfirm>
          <>
            <p>Template list</p>
            <List
              size="small"
              bordered
              dataSource={searchResults}
              renderItem={(item) => <List.Item className='cursor-pointer list' onClick={() => addToArray(item)}>{item.name}</List.Item>} />
          </>
        </>)}
      <Divider />
      {!files && <FileDetails  template={template} setGifFiles={setFiles} setGif={true} />}
      {files && (<>
        <p>Uploaded Files:</p>
        <List
          size="small"
          bordered
          dataSource={files}
          renderItem={(item) => <List.Item className='cursor-pointer list' onClick={() => addToArray({id: '-', name: item.value})}>{item.value}</List.Item>}
        />
      </>)}
      <Divider />
      <div>
        <h5>Order of Gif build:</h5>
        <ReactSortable  list={order} setList={setOrder}>
          {order.map((item) => (
            <div key={item.id} style={{display: 'flex', justifyContent: 'space-between'}}>
              <span style={{padding: '8px 16px', border: '1px solid #ddd', borderRadius: '10px', width: '60%', marginRight: '15px', marginBottom: '2px'}} className='list cursor-pointer'>{item.name}</span>
              {item.id !== '-' && <span>Duration(in centisecond): <InputNumber min={1} max={1000} defaultValue={delayData[item.id] || 125} onChange={(e) => onChange(e, item.id)} /></span>}
            </div>
          ))}
        </ReactSortable>
      </div>
      <Divider />
      <div className='right'>
        <Button disabled={order.length === 0} className='button' onClick={handleSubmit}>Submit</Button>
      </div>
      <GifComposerForm
        isModalOpen={openModal}
        template={template}
        setIsModalOpen={setOpenModal}
        orderCount={searchResults.length}
      />
    </>
  )
}

export default GifDetails