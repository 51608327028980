import React from 'react'
import { render, waitFor, screen } from '@testing-library/react'
import '@testing-library/jest-dom'
import apiClient from 'components/lib/api/apiClient'
import useCanvas from '../useCanvas'

jest.mock('components/lib/api/apiClient')

const templateId = 1

const mockApiResponse = {
  canvas: 'some data'
}

beforeEach(() => {
  apiClient.composer = {
    getCanvas: jest.fn(() => {
      return Promise.resolve(mockApiResponse)
    })
  }
})

const renderComponent = () => {
  const HookWrapper = () => {
    const data = useCanvas(templateId)

    return (
      <div>
        <div data-testid="canvas">{data.canvas ? JSON.stringify(data.canvas) : 'null'}</div>
      </div>
    )
  }

  return render(<HookWrapper />)
}

test('fetches and displays templates', async () => {
  renderComponent()

  await waitFor(() => {
    expect(screen.getByTestId('canvas')).toHaveTextContent(JSON.stringify(mockApiResponse.canvas))
  })
})
