import React, { useCallback, useEffect, useState } from 'react'
import apiClient from 'components/lib/api/apiClient'
import useCable from 'components/hooks/useCable'
import { Button, Space, Table } from 'antd'
import { Link } from 'react-router-dom'
import Event from './Event'
import RequestData from './RequestData'
import Actioncable from 'actioncable'

const Request = ({template_id, requestId }) => {
  const ws = useCable()
  const [requests, setRequests] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [openRequestModal, setOpenRequestModal] = useState(false)
  const [selectedRequest, setSelectedRequest] = useState(null)
  const [selectedEvent, setSelectedEvent] = useState([])
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: requests.count,
  })
  const [restartId, setRestartId] = useState(null)
  const fetchData = useCallback( async () => {
    try {
      const response = await apiClient.requests.getTestRequests(template_id)
      if(response.success){
        setRequests(response.requests)
      }
    } catch (error) {
      console.log('Failed to fetch template data:', error)
    }
  }, [template_id])

  const updateRequest = useCallback((updatedData) => {
    const updatedRequest = requests.map(request => {
      if (request.id === requestId) {
        const formated = { ...request, ...updatedData }
        return formated
      }
      return request
    })

    setRequests(updatedRequest)
  },[requests, requestId])

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination
    })
  }

  const restartRequest = (async (id) => {
    setRestartId(id)
    try {
      await apiClient.requests.restartRequest(id)
    } catch (error) {
      console.log(error)
    }
  })

  const viewDetails = (request) => {
    setSelectedRequest(request)
    setOpenRequestModal(true)
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    if(requestId) fetchData()
  }, [fetchData, requestId])

  useEffect(() => {
    if(!requestId && !restartId) return
    const cable = Actioncable.createConsumer(ws)
    const uniqId = restartId || requestId
    const requestChannel = cable.subscriptions.create(
      { channel: 'RequestChannel', requestId: uniqId },
      {
        connected() {
          if(process.env.RAILS_ENV !== 'production') console.log('Connected to Action Cable for Requests')
        },
        disconnected() {
          if(process.env.RAILS_ENV !== 'production') console.log('Disconnected from Action Cable for Requests')
        },
        received(data) {
          setRestartId(null)
          updateRequest(data.request)
        },
      }
    )

    return () => {
      requestChannel.unsubscribe()
    }
  }, [requestId, updateRequest, ws, restartId])

  const columns = [
    { title: 'Created At', dataIndex: 'created_at' },
    { title: 'ID', dataIndex: 'id' },
    { title: 'State', dataIndex: 'state' },
    {
      title: 'Events',
      dataIndex: 'events',
      render: (_, record) => (
        <Space size="middle">
          <Button type='link' onClick={() => {
            setSelectedEvent(record.events)
            return setOpenModal(true)
          }}>View</Button>
        </Space>
      )
    },
    {
      title: 'Asset',
      dataIndex: 'asset',
      render: (_, record) => {
        if(record.asset_url){
          return (
            record.state === 'done' && <Space size="middle">
              <Link target="_blank" to={record.asset_url}>View</Link>
            </Space>
          )
        }
        return null
      }
    },
    {
      title: 'Details',
      dataIndex: 'details',
      render: (_, record) => {
        return (
          <Button
            type='link'
            // disabled={!(['delayed', 'failed'].includes(record.state) && !restartId)}
            onClick={() => viewDetails(record)}>
              View
          </Button>
        )
      }
    },
  ]

  return (
    <>
      <Table
        columns={columns}
        dataSource={requests}
        pagination={pagination}
        onChange={handleTableChange}
      />
      <Event
        openModal={openModal}
        setOpenModal={setOpenModal}
        selectedEvent={selectedEvent}
        setSelectedEvent={setSelectedEvent}
      />
      {openRequestModal && <RequestData
        openModal={openRequestModal}
        setOpenModal={setOpenRequestModal}
        selectedRequest={selectedRequest}
        setSelectedRequest={setSelectedRequest}
        restartRequest={restartRequest}
      />}
    </>
  )
}

export default Request