import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Tabs, Spin, Divider } from 'antd'
import useCanvas from 'components/hooks/useCanvas'
import grapesjs from 'grapesjs'
import ImageEditor from 'grapesjs-tui-image-editor'
import FontModal from './FontModal'
import VariableModal from './VariableModal'
import cookies from 'components/util/cookies/url/cookies'
import Conditionals from './Conditionals'
import { useImageComposer } from 'components/context/ImageComposerContext'
import { fontDownload, photo, qrCode, title, varIcon, brush } from 'components/constants/Icons'
import { useVariableActions } from '../../context/VariableContext'
const ss = ', sans-serif'
const barcodeType = [
  { id: 'code128', name: 'Code 128'},
  { id: 'code39', name: 'Code 39'},
  { id: 'code93', name: 'Code 93'},
  { id: 'qrcode', name: 'QR Code'},
  { id: 'microqrcode', name: 'Micro QR Code'},
  { id: 'ean13', name: 'EAN-13'},
  { id: 'ean8', name: 'EAN-8'},
  { id: 'upca', name: 'UPC-A'},
  { id: 'upce', name: 'UPC-E'},
  { id: 'itf14', name: 'ITF 14'},
  { id: 'rationalizedCodabar', name: 'Codabar'},
  { id: 'msi', name: 'MSI'},
  { id: 'datamatrix', name: 'Data Matrix'},
  { id: 'pdf417', name: 'PDF417'},
  { id: 'maxicode', name: 'MaxiCode'},
  { id: 'azteccode', name: 'Aztec Code'},
  { id: 'databarexpanded', name: 'GS1 DataBar'},
  { id: 'dotcode', name: 'DotCode'}
]

const ImageComposer = ({template}) => {
  const [addedComponent, setAddedComponent] = useState(null)
  const authToken = cookies.authToken.get()
  const [editOn, setEditor] = useState(false)
  const [prop, setProp] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [openVariables, setOpenVariables] = useState(false)
  const [selectedComponentSrc, setSelectedComponentSrc] = useState(null)
  const {
    setDirtyCount,
    setStateEditor,
    stateEditor,
    dirtyCount,
    setConditionId,
    removedId,
    setRemovedId
  } = useImageComposer()
  const { updateVariable } = useVariableActions()
  const items1 = [
    {
      key: '1',
      label: `Blocks`,
      children: (<div className='blocks'></div>),
      forceRender: true,
    },
    {
      key: '2',
      label: `Layers`,
      children: (<div className='layers'></div>),
      forceRender: true,
    },
    {
      key: '3',
      label: `Extra`,
      children: (
        <div className='extra' style={{display: 'flex'}}>
          <button className="custom-block-button blockBtn fontBtn" value="1" text="Fonts" aria-label="Font" onClick={() => setOpenModal(true)}>
            <span className="custom-icon" dangerouslySetInnerHTML={{ __html: fontDownload }} />
            <span className="custom-font-text">Font</span>
          </button>
          <button className="custom-block-button blockBtn" value="1" text="Variables" aria-label="Variables" onClick={() => setOpenVariables(true)}>
            <span className="custom-icon" dangerouslySetInnerHTML={{ __html: varIcon }} />
            <span className="custom-font-text">Variables</span>
          </button>
        </div>
      ),
      forceRender: true,
    },
  ]
  const items2 = [
    {
      key: '1',
      label: `Style Manager`,
      children: (<div className='styleManager'></div>),
      forceRender: true,
    },
    {
      key: '2',
      label: `Settings`,
      children: (<>
        <div className='settings'></div>
        <Divider />
        <Conditionals template={template} />
      </>),
      forceRender: true,
    },
  ]
  const defaultFonts = [
    'Arial, Helvetica' + ss,
    'Arial Black, Gadget' + ss,
    'Brush Script MT' + ss,
    'Courier New, Courier, monospace',
    'Georgia, serif',
    'Helvetica' + ss,
    'Impact, Charcoal' + ss,
    'Lucida Sans Unicode, Lucida Grande' + ss,
    'Tahoma, Geneva' + ss,
    'Times New Roman, Times, serif',
    'Trebuchet MS, Helvetica' + ss,
    'Verdana, Geneva' + ss,
  ].map(font => {
    return { value: font, name: font.split(',')[0] }
  })
  const fontOps = useRef(defaultFonts)
  const getFontType = (extension) => {
    switch (extension) {
      case 'ttf':
        return 'truetype'
      case 'woff':
        return 'woff'
      case 'woff2':
        return 'woff2'
      case 'otf':
        return 'opentype'
      default:
        return ''
    }
  }

  const { canvas } = useCanvas(template.id)

  const updateSrc = () => {
    const typeTrait = addedComponent.getTrait('barcodeType')
    const barcodeValueTrait = addedComponent.getTrait('barcodeValue')
    const scaleValueTrait = addedComponent.getTrait('scale')
    const type = typeTrait.get('value')
    const barcodeValue = barcodeValueTrait.get('value')
    if (!type || !barcodeValue) {
      return
    }
    const scaleValue = scaleValueTrait.get('value') || 1
    const baseUrl = '/api/barcode/'
    const codeParam = typeTrait ? `bcid=${type}` : ''
    const scale = `&scale=${scaleValue}`
    const textParam = barcodeValueTrait ? `&text=${barcodeValue}` : ''
    const src = `${baseUrl}?${codeParam}${scale}${textParam}`

    addedComponent.set('src', src)
  }

  const parserCss = useCallback((arr) => {
    let result = ''
    for (const value of arr) {
      if(value.type === 'font' || value.type === 'application'){
        const name = value?.name?.split('.')[0]
        const extention = value?.name?.split('.')[1]
        const fontType = getFontType(extention)
        if(!fontOps.current.map(a => a.name).includes(name)){
          result = result + `
          @font-face {
            font-family: "${value.name ? value.name : name}";
            src: url(${value.src}) format(${fontType});
          }`

          fontOps.current = [...fontOps.current, { value: `"${value.name}"`, name: name }]
        }
      }
    }
    return result
  },[])

  useEffect(() => {
    const composer = canvas?.composer
    if(composer && !editOn){
      const editor = grapesjs.init({
        container: '#editor',
        keepUnusedStyles: true,
        plugins: [ImageEditor],
        pluginsOpts: {
          [ImageEditor]: {
            config: {
              includeUI: {
                menuBarPosition: 'left',
                theme: {
                  'common.backgroundColor': '#eee',
                  'submenu.backgroundColor': '#eee',
                },
              }
            }
          }
        },
        fromElement: false,
        dragMode: "absolute",
        noticeOnUnload: false,
        width: "100%",
        height: "100%",
        storageManager: {
          type: "remote",
          id: "pcp-",
          autosave: false,
          autoload: false,
          options: {
            remote: {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${authToken}`,
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
              },
              urlLoad: `/web/templates/${template.id}/composer/canvas`,
              urlStore: `/web/templates/${template.id}/composer/canvas`,
              fetchOptions: opts => (opts.method === 'POST' ? {method: 'PATCH'} : {}),
              onStore: (data, editor) => {
                const pagesHtml = editor.Pages.getAll().map(page => {
                  const component = page.getMainComponent()
                  return {
                    html: editor.getHtml({component}),
                    css: editor.getCss({component})
                  }
                })
                const prop = editor.StyleManager.getProperty('typography', 'font-family')
                const results = parserCss(data.assets)
                prop.set('options', fontOps.current)
                editor.Canvas.getDocument().head.insertAdjacentHTML('beforeend', `<style>${results}</style>`)
                return {data, html: pagesHtml[0].html, css: pagesHtml[0].css}
              },
              onLoad: (result) => {
                if (result.composer.data) {
                  return result.composer.data
                }
              }
            }
          }
        },
        assetManager: {
          assets: [
            {type: 'image'},
            {type: 'svg'},
            {type: 'pdf'},
            {type: 'text'}
          ]
        },
        blockManager: {
          appendTo: '.blocks',
          blocks: [
            {
              id: "image",
              select: true,
              content: {
                type: 'image',
                name: 'image',
                traits: [
                  'alt',
                  { type: 'text', name: 'src', placeholder:'https://google.com', changeProp: 1 },
                  'id',
                ],
              },
              media: `<button class="custom-block-button blockBtn" value="1" text="Image" aria-label="Image"><span class="custom-icon">${photo}</span><span class="custom-font-text">Image</span></button>`,
              activate: true
            },
            {
              id: "barcode",
              select: true,
              content: {
                type: 'image',
                name: 'barcode',
                tagName: 'img',
                traits: [
                  'id',
                  'alt',
                  {
                    type: 'select',
                    label: 'Barcode Type',
                    name: 'barcodeType',
                    options: barcodeType,
                  },
                  {
                    type: 'text',
                    name: 'barcodeValue',
                    label: 'Barcode Value',
                  },
                  {
                    type: 'text',
                    name: 'scale',
                    label: 'Scale',
                  },
                ],
              },
              media: `<button class="custom-block-button blockBtn" value="1" text="Barcode" aria-label="Barcode"><span class="custom-icon">${qrCode}</span><span class="custom-font-text">Barcode</span></button>`,
              activate: false
            },
            {
              id: "text",
              content: '<div data-gjs-type="text">Insert your text here</div>',
              media: `<button class="custom-block-button blockBtn" value="1" text="Text" aria-label="Text"><span class="custom-icon">${title}</span><span class="custom-font-text">Text</span></button>`,
            },
            {
              id: 'draw',
              content: `
                <img data-gjs-name='draw-block' src="data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' width='400' height='400'><rect width='400' height='400' fill='white'/></svg>" style="width: 100px; height: 100px">
              `,
              media: `<button class="custom-block-button blockBtn" value="1" text="Draw" aria-label="Draw"><span class="custom-icon">${brush}</span><span class="custom-font-text">Draw</span></button>`,
            }
          ]
        },
        layerManager: {
          appendTo: '.layers',
          hidable: true,
          sortable: true,
          showHover: true,
        },
        styleManager: {
          appendTo: '.styleManager',
          sectors: [
            {
              name: 'Dimension',
              open: false,
              buildProps: ['width', 'height', 'max-width', 'min-height', 'margin', 'padding', 'top', 'right', 'left', 'bottom'],
            },
            {
              name: 'Typography',
              open: false,
              buildProps: [
                'font-family',
                'font-size',
                'font-weight',
                'letter-spacing',
                'color',
                'line-height',
                'text-align',
                'text-shadow'
              ],
            },
            {
              name: 'Decorations',
              open: false,
              buildProps: ['background-color', 'border-radius', 'border', 'box-shadow', 'background'],
            },
            {
              name: 'Extra',
              open: false,
              buildProps: ['opacity'],
            }
          ]
        },
        traitManager: {
          appendTo: '.settings',
        },
        deviceManager: {
          default: 'pcp_image_canvas',
          devices: [
            {id: 'pcp_image_canvas', name: "Image Canvas", width: composer.dimensions.split("x")[0] + 'px', height: composer.dimensions.split("x")[1] + 'px'}
          ]
        },
        showDevices: false,
        panels: { defaults: [] },
      })

      editor.loadProjectData(composer.data || {})
      editor.Canvas.getModel().updateDevice()
      setProp(editor.StyleManager.getProperty('typography', 'font-family'))
      setStateEditor(editor)
      setEditor(true)
    }
    stateEditor && stateEditor?.Canvas?.getDocument()?.head.insertAdjacentHTML('beforeend', `<style>body{overflow: hidden}; ${parserCss(composer.data.assets || [])}</style>`)
    prop?.set('options', fontOps.current)
  }, [canvas, stateEditor, editOn, parserCss, prop, authToken, setStateEditor, fontOps, template.id])

  let editorDirtyCount = stateEditor?.getDirtyCount()
  stateEditor?.on('change', () => {
    const newDirtyCount = stateEditor.getDirtyCount()
    const htmlVars = stateEditor.getHtml().match(/\{\{(.+?)\}\}/g) || []
    const cssVars = stateEditor.getCss().match(/\{\{(.+?)\}\}/g) || []
    if (addedComponent && addedComponent.attributes.name === 'barcode') updateSrc()
    if (newDirtyCount !== editorDirtyCount) {
      updateVariable([...htmlVars, ...cssVars])
      setDirtyCount(dirtyCount + 1)
      editorDirtyCount = newDirtyCount
    }
  })

  stateEditor?.on('component:selected', function(e){
    const traits = e.getTraits().map(t => t.id)
    if(e.getName() === 'Image' && traits.length === 1){
      e.addTrait('src')
      e.addTrait('id')
    }
    if(e.getName() === 'barcode' && traits.length === 1){
      e.addTrait('id')
      e.addTrait({
        type: 'select',
        label: 'Barcode Type',
        name: 'barcodeType',
        options: barcodeType
      })
      e.addTrait('barcodeValue')
      e.addTrait('scale')
      setAddedComponent(e)
    }
    setConditionId(e.ccid || null)
  })

  stateEditor?.on('component:remove', (e) => {
    if(!removedId) setRemovedId(e.ccid)
  })

  stateEditor?.on('asset:open', () => {
    const selectedComponent = stateEditor.getSelected()
    if(!selectedComponent) return
    if(selectedComponent.is('image')){
      setSelectedComponentSrc(selectedComponent.getSrcResult())
      setAddedComponent(selectedComponent)
    }
  })

  stateEditor?.on('asset:close', () => {
    const newFonts = parserCss(stateEditor.AssetManager.getAll().models.map(m => m.attributes))
    stateEditor.Canvas.getDocument().head.insertAdjacentHTML('beforeend', `<style>${newFonts}</style>`)
    if (addedComponent?.is('image') && addedComponent.getSrcResult() !== selectedComponentSrc ) {
      stateEditor.runCommand('resize-image')
    } else {
      setSelectedComponentSrc(null)
      setAddedComponent(null)
    }
  })

  stateEditor?.Commands.add('resize-image', {
      run: function() {
          addedComponent.setStyle({position: 'absolute'})
          const dimensions = canvas.composer.dimensions.split("x")
          const canvasWidth = parseInt(dimensions[0], 10)
          const canvasHeight = parseInt(dimensions[1], 10)
          const componentWidth = addedComponent.getEl().offsetWidth
          const componentHeight = addedComponent.getEl().offsetHeight
          const scaleFactor = Math.min(canvasWidth / componentWidth, canvasHeight / componentHeight)
          const newWidth = addedComponent.getEl().offsetWidth * scaleFactor
          const newHeight = addedComponent.getEl().offsetHeight * scaleFactor
          if(scaleFactor < 1){
            addedComponent.setStyle({
              position: 'absolute',
              width: newWidth + 'px',
              height: newHeight + 'px',
              top: 0,
              left: 0,
            })
          }
          setAddedComponent(null)
      }
  })

  stateEditor?.on('component:add', function(component) {
    if (component.get('type') === 'image') {
      setAddedComponent(component)
    }
  })

  const addFont = (font) => {
    const extention = font?.name?.split('.')[1]
    const name = font?.name?.split('.')[0]
    if(!extention) {
      return
    }
    if(fontOps.current.map(a => a.name).includes(name)){
      return alert('This font has already been added!')
    }
    const fontType = getFontType(extention)
    const fontFace = `
    @font-face {
      font-family: "${font.name}";
      src: url(${font.file}) format(${fontType});
      }`
    fontOps.current = [...fontOps.current, { value: `"${font.name}"`, name: name }]
    prop.set('options', fontOps.current)
    stateEditor.Canvas.getDocument().head.insertAdjacentHTML('beforeend', `<style>${fontFace}</style>`)
    const asset = {
      src: `${font.file}`,
      type: 'font',
      name: `${font.name}`,
    }
    stateEditor.AssetManager.add(asset)
  }

  if(!template) return <Spin />
  return (
    <>
      <div className="editor-row">
      <div className="editor-canvas">
        <div className="card-container">
          <h5>Custom Image Builder</h5>
          <Tabs defaultActiveKey="1" items={items1} />
        </div>
        <div id="editor" className='grapesjs-generated-element'></div>
        <div className="card-container" style={{ right: '15px' }}>
          <h5>Editor</h5>
          <Tabs defaultActiveKey="1" items={items2} />
        </div>
      </div>
    </div>
    <FontModal openModal={openModal} setOpenModal={setOpenModal} template={template} handleFontClick={addFont} />
    <VariableModal openModal={openVariables} setOpenModal={setOpenVariables} template={template} />
    </>
  )
}

export default ImageComposer