import React, { useState, useRef } from 'react'
import { Modal, Form, Upload, Button, List } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import apiClient from 'components/lib/api/apiClient'
import { useBannerActions } from 'components/context/BannerContext'

const FontModal = ({ openModal, setOpenModal, template, handleFontClick }) => {
  const [form] = Form.useForm()
  const [fontFiles, setFontFiles] = useState([])
  const uploadButtonRef = useRef(null)
  const {setBannerMessage, setBannerError} = useBannerActions()

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (event) => {
        resolve(event.target.result)
      }
      reader.onerror = (error) => {
        reject(error)
      }
      reader.readAsDataURL(file)
    })
  }

  const handleOk = async () => {
    try {
      const {font} = await form.validateFields()
      let response
      if(font.file.name.includes('.ttf')){
        const formData = new FormData()
        formData.append('font', font.file)
        formData.append('name', font.file.name)
        response = await apiClient.accounts.uploadRawFont(template.account_id, formData)
      }
      else {
        const fontFileBase64 = await convertFileToBase64(font.file)
        response = await apiClient.accounts.uploadFont(template.account_id, { font: fontFileBase64, name: font.file.name })
      }
      if(response.success){
        form.resetFields()
        setFontFiles([])
        setOpenModal(false)
        handleFontClick(response.font)
        setBannerMessage('Font was uploaded and added to list')
      } else setBannerError('Font failed to upload')
    } catch (error) {
      console.error(error)
    }
  }

  const handleCancel = () => {
    form.resetFields()
    setFontFiles([])
    setOpenModal(false)
  }

  const beforeUpload = (file) => {
    setFontFiles([file])
    return false
  }

  const handleReset = () => {
    if (uploadButtonRef.current) {
      uploadButtonRef.current.removeAttribute('disabled')
    }
    setFontFiles([])
    form.resetFields()
  }

  return (
    <Modal
      open={openModal}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      aria-hidden="true"
      aria-labelledby="fontModalLabel"
      tabIndex="-1"
      className="font-modal"
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h5 id="fontModalLabel" className="modal-title">
              Font
            </h5>
          </div>
          <div className="modal-body">
            <Form
              form={form}
              onFinish={handleOk}
              className="font-form"
            >
              <div className="mb-3">
                <Form.Item label="Font File" name="font">
                  <Upload
                    fileList={fontFiles}
                    beforeUpload={beforeUpload}
                    onRemove={handleReset}
                  >
                    <Button icon={<UploadOutlined />}>Select Font File</Button>
                  </Upload>
                </Form.Item>
              </div>
              <Button type="primary" htmlType="submit" className="save-font-btn" id="uploadButton">
                Upload
              </Button>
            </Form>
            <hr />
            <h5>Existing Fonts</h5>
            <div className="font-list-container">
              <List
                dataSource={template.fonts}
                renderItem={(font) => (
                  <List.Item
                    className="cursor-pointer list"
                    onClick={() => handleFontClick(font)}
                  >
                    {font.name}
                    <input type="hidden" value={font.file} />
                  </List.Item>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default FontModal
