import { Modal, Table } from 'antd'
import React from 'react'
const columns = [
  { title: 'Created At', dataIndex: 'created_at' },
  { title: 'Type', dataIndex: 'type' },
  { title: 'State', dataIndex: 'state' },
  { title: 'Host', dataIndex: 'host' },
  { title: 'Tags', dataIndex: 'tags' },
]
const Event = ({openModal, setOpenModal, selectedEvent, setSelectedEvent}) => {
  const handleCancel = () => {
    setOpenModal(false)
    setSelectedEvent([])
  }
  return (
    <Modal title="Events" open={openModal} onCancel={handleCancel} footer={null} width='auto'>
      <Table 
        columns={columns}
        dataSource={selectedEvent}
        pagination={false}
      />
    </Modal>
  )
}

export default Event