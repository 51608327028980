import React, { useEffect } from 'react'
import { Form, Input, Button, Layout } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import apiClient from 'components/lib/api/apiClient'
import logo from 'components/sinch-logo.png'
import cookies from 'components/util/cookies/url/cookies'
import { useBannerActions } from 'components/context/BannerContext'
import Banner from 'components/common/Banner'
import { useSearchParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useProfile } from 'components/context/ProfileContext'
import useLocation from 'components/hooks/useLocation'
export const LoginPage = () => {
  const [searchParams] = useSearchParams()
  const redirectPath = searchParams.get('redirect')
  const { profile } = useProfile()
  const { Content } = Layout
  const { setBannerMessage, setBannerError } = useBannerActions()
  const location = useLocation()
  const onFinish = async (creds) => {
    try {
      const authToken = await apiClient.authentication.logIn(creds)
      if(authToken){
        cookies.authToken.set(authToken)
        window.location = redirectPath || '/'
        setBannerMessage('You have been logged in!')
      } else setBannerError('Unable to login! Wrong username or password.')
    } catch (error) {
      setBannerError('Somthing went wrong with loggin in!')
      cookies.authToken.remove()
      console.log(error)
    }
  }

  useEffect(() => {
    if(profile) location('/')
  }, [profile, location])
  return (
    <Layout className="login-form-layout">
      <Helmet>
        <title>PCP - Login Page</title>
      </Helmet>
      <div>
        <Banner />
      </div>
      <Content style={{marginBottom: '1%'}}>
        <img style={{width: '175px'}} src={logo} alt="Logo" />
      </Content>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Please input your Email!',
            },
          ]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your Password!',
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button">
            Log in
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  )
}

export default LoginPage