export const createMockTemplate = {
  template: {
    id: '',
    account_id: '',
    required_variables: [],
    tag: '',
    kind: '',
    source: '',
    version: '1',
    name: '',
    repo_server: '',
    repo_account: '',
    repo_name: '',
    repo_branch: '',
  }
}

export const createMockAccount = [{account_name: 'Name'}]

export const createMockSearch = {
  query: '',
  page: 1,
  sortBy: 'created_at',
  sortDirection: 'desc',
  statuses: ['Active'],
  pageSize: 10,
}

export const createMockComposer = ({
  conditionId = null,
  removedId = null,
  dirtyCount = 0,
  stateEditor = null,
  conditionals = [],
} = {}) => ({
  conditionId,
  setConditionId: (value) => conditionId = value,
  removedId,
  setRemovedId: (value) => removedId = value,
  dirtyCount,
  setDirtyCount: (value) => dirtyCount = value,
  stateEditor,
  setStateEditor: (value) => stateEditor = value,
  conditionals,
  setConditionals: (value) => conditionals = value,
})

export const createMockProfile = {
  isLoading: false,
  profile: {
    key: "6543bfe437aacd0022c6ef22",
    id:"6543bfe437aacd0022c6ef22",
    account_id:"6543bfe337aacd0022c6ef1d",
    email:"some.email@sinch.com",
    first_name: 'some',
    last_name: 'name',
    role:"admin",
    account: {
      key: '6543bfe337aacd0022c6ef1d',
      id_rsa: 'rsa',
      name: 'Sinch',
      uuid: 'some-uuid-here'
    }
  }
}