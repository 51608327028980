const AuthenticationEndpoint = (axios) => {
  const logIn = async (params) => {
    const requestData = { session: { ...params } }
    const { data } = await axios.post('/web/sessions', requestData)
    return data.token
  }

  return {
    logIn,
  }
}

export default AuthenticationEndpoint