import { useCallback, useEffect, useState } from 'react'
import apiClient from 'components/lib/api/apiClient'

const useCanvas = (template_id) => {
  
  const [canvas, setCanvas] = useState(null)
  const getCanvasInfo = useCallback( async () => {
    try {
      const data = await apiClient.composer.getCanvas(template_id)
      setCanvas(data)
    } catch (error) {
      setCanvas(null)
    }
  }, [template_id])

  useEffect(() => {
    getCanvasInfo()
  }, [getCanvasInfo])

  return { canvas }
}

export default useCanvas
