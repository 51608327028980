const ComposerEndpoint = (axios) => {
  const updateComposer = async (id, params) => {
    const { data } = await axios.patch(`/web/templates/${id}/composer/canvas`, params)
    return data
  }

  const getCanvas = async (id) => {
    const { data } = await axios.get(`/web/templates/${id}/composer/canvas`)
    return data
  }
  return {
    getCanvas,
    updateComposer,
  }
}

export default ComposerEndpoint