import React from 'react'
import { Layout } from 'antd'
import { useLocation } from 'react-router-dom'
import ApplicationProvider from 'components/App/ApplicationProvider'
import Nav from 'components/Nav'
import AppRoutes from 'components/App/AppRoutes'
import RequestRate from 'components/RequestRate'

const { Header, Content, Footer } = Layout
const overriddenLayoutPaths = [
	'/login',
	'/set-password'
]

export const AppContent = () => {
	const { pathname } = useLocation()
	const hideNav = React.useMemo(() => {
		return overriddenLayoutPaths.includes(pathname)
	}, [pathname])
	return (
		<ApplicationProvider>
			<Layout style={{ minHeight: '100vh' }}>
				<Layout>
					{!hideNav && <Header>
				    <Nav />
					</Header>}
					<Content style={{padding: '0 15px'}}>
						<AppRoutes />
					</Content>
					{!hideNav && <Footer>
				    <RequestRate />
					</Footer>}
				</Layout>
			</Layout>
		</ApplicationProvider>
	)
}

export default AppContent