import React, { createContext, useMemo, useState } from 'react'
import useProvidedContext from 'components/hooks/useProvidedContext'

export const ImageComposerContext = createContext()
export const ImageComposerActionContext = React.createContext()

export const ImageComposerProvider = ({ children }) => {
  const [conditionId, setConditionId] = useState(null)
  const [removedId, setRemovedId] = useState(null)
  const [dirtyCount, setDirtyCount] = useState(0)
  const [stateEditor, setStateEditor] = useState(null)
  const [conditionals, setConditionals] = useState([])
  const actions = useMemo(() => {

    const reset = () => {
      setConditionId(null)
      setRemovedId(null)
      setDirtyCount(0)
      setStateEditor(null)
      setConditionals([])
    }
    return {
      reset,
    }
  }, [])
  return (
    <ImageComposerActionContext.Provider value={actions}>
      <ImageComposerContext.Provider
        value={{
          conditionId,
          setConditionId,
          removedId,
          setRemovedId,
          dirtyCount,
          setDirtyCount,
          stateEditor,
          setStateEditor,
          conditionals,
          setConditionals,
        }}
      >
        {children}
      </ImageComposerContext.Provider>
    </ImageComposerActionContext.Provider>
  )
}

export const useImageComposer = () => {
  return useProvidedContext('ImageComposerProvider', ImageComposerContext)
}

export const useImageComposerActions = () => {
  return useProvidedContext('ImageComposerProvider', ImageComposerActionContext)
}

