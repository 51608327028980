import React, { useEffect, useState } from 'react'
import { Modal, Form, Button, List, Space, Input } from 'antd'
import apiClient from 'components/lib/api/apiClient'
import { useTemplateActions } from 'components/context/TemplateContext'
import { CloseOutlined, DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons'
import { useVariableActions } from 'components/context/VariableContext'

const VariableModal = ({ openModal, setOpenModal, template }) => {
  const [form] = Form.useForm()
  const { updateTemplate } =  useTemplateActions()
  const [required, setRequired] = useState([])
  const [edit, setEdit] = useState(null)
  const [inputForm, setInputForm] = useState('')
  const [selectedItem, setSelectedItem] = useState('')
  const [submittable, setSubmittable] = React.useState(false)
  const { updateVariable } = useVariableActions()

  const handleCancel = () => {
    setOpenModal(false)
    setInputForm('')
    setSelectedItem('')
    setEdit(null)
  }

  const handleVariableCancel = () => {
    setInputForm('')
    setSelectedItem('')
    setEdit(null)
  }

  const handleSubmit = async (variables) => {
    try {
      const partId = template.parts[0].id
      const id = template.id
      const partResponse = await apiClient.parts.updatePart(id, partId, {options: {...template.parts[0].options, hidden_variables: variables}})
      if(partResponse.success){
        const templateResponse = await apiClient.templates.getTemplate(id)
        if(templateResponse.success){
          updateTemplate(id, templateResponse.template)
          updateVariable(variables)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleFormSubmit  = async () => {
    const { variable } = await form.validateFields()
    const newVarSet = [...required, variable]
    setRequired(newVarSet)
    await handleSubmit(newVarSet)
    form.resetFields()
  }

  const handleEdit = (ruleName, index) => {
    setEdit(index)
    setInputForm(ruleName)
    setSelectedItem(ruleName)
  }

  const handleUpdate = async () => {
    if (required.includes(inputForm)) {
      console.log(`${inputForm} already exists!`)
      return
    }
    const updateVariables = required.map((name) => name === selectedItem ? inputForm : name)
    setRequired(updateVariables)
    await handleSubmit(updateVariables)
    setEdit(null)
  }

  const handleDelete = async (ruleName) => {
    const updateVariables = required.filter(name => name !== ruleName)
    setRequired(updateVariables)
    await handleSubmit(updateVariables)
  }

  const enableSubmit = (e) => {
    if(e.target.value){
      setSubmittable(true)
    } else setSubmittable(false)
  }

  useEffect(() => {
    if(template){
      const { parts } =  template
      setRequired(parts[0].options['hidden_variables'] || [])
    }
  }, [template])

  return (
    <Modal
      open={openModal}
      onCancel={handleCancel}
      footer={null}
      aria-hidden="true"
      aria-labelledby="fontModalLabel"
      tabIndex="-1"
      className="font-modal"
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h5 id="fontModalLabel" className="modal-title">
              Hidden Variables
            </h5>
          </div>
          <div className="modal-body">
            <Form
              form={form}
              onFinish={handleFormSubmit}
              className="font-form"
            >
              <Space.Compact
                style={{
                  width: '100%',
                }}
              >
                <Form.Item label="Add variable" name='variable'>
                  <Input onChange={(e) => enableSubmit(e)} />
                </Form.Item>
                <Button type="primary" htmlType="submit" className="save-font-btn" id="uploadButton" disabled={!submittable}>
                  Submit
                </Button>
              </Space.Compact>
            </Form>
            <div className="mb-3">
              <List
                size="small"
                bordered
                dataSource={required}
                renderItem={(item, index) => (
                <List.Item style={{display: 'flex', justifyContent: 'space-between'}}>
                  {edit === index ? <Input name='name' defaultValue={inputForm} style={{width: '156px'}} onChange={(e) => setInputForm(e.target.value)} /> : item}
                  <span>
                    {
                      edit === index
                      ? <>
                        <Button style={{marginRight: '10px'}} onClick={handleUpdate}><SaveOutlined /></Button>
                        <Button onClick={handleVariableCancel}><CloseOutlined /></Button>
                      </>
                      : <>
                        <Button style={{marginRight: '10px'}} onClick={() => handleEdit(item, index)}><EditOutlined /></Button>
                        <Button danger onClick={() => handleDelete(item)}><DeleteOutlined /></Button>
                      </>
                    }
                  </span>
                </List.Item>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default VariableModal
