import ComposerEndpoint from '../ComposerEndpoint'

describe('ComposerEndpoint', () => {
  const axios = {
    patch: jest.fn(),
    get: jest.fn(),
  }
  const composer = ComposerEndpoint(axios)

  afterEach(() => {
    jest.clearAllMocks()
  })

  test('updateComposer calls the API and returns the response', async () => {
    const templateId = 1
    const params = {}
    const mockResponse = {}
    axios.patch.mockResolvedValue({ data: mockResponse })

    const result = await composer.updateComposer(templateId, params)

    expect(axios.patch).toHaveBeenCalledWith(`/web/templates/${templateId}/composer/canvas`, params)
    expect(result).toEqual(mockResponse)
  })

  test('getCanvas calls the API and returns the response', async () => {
    const templateId = 1
    const mockResponse = {}
    axios.get.mockResolvedValue({ data: mockResponse })

    const result = await composer.getCanvas(templateId)

    expect(axios.get).toHaveBeenCalledWith(`/web/templates/${templateId}/composer/canvas`)
    expect(result).toEqual(mockResponse)
  })

})