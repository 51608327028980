import '@testing-library/jest-dom'
import axios from 'axios'
import axiosMock from 'axios'
import AuthenticationEndpoint from './endpoints/AuthenticationEndpoint'
import TemplatesEndpoint from './endpoints/TemplatesEndpoint'
import UsersEndpoint from './endpoints/UsersEndpoint'
import AccountsEndpoint from './endpoints/AccountsEndpoint'
import RequestsEndpoint from './endpoints/RequestsEndpoint'
import ComposerEndpoint from './endpoints/ComposerEndpoint'
import PartsEndpoint from './endpoints/PartsEndpoint'
import QueueEndpoint from './endpoints/QueueEndpoint'
import NotificationEndpoint from './endpoints/NotificationEndpoint'
jest.mock('axios')

const mockAuthenticationEndpoint = {
  logIn: jest.fn().mockResolvedValue({ data: 'Mocked login response' }),
  logOut: jest.fn().mockResolvedValue({ data: 'Mocked logout response' })
}
const mockTemplatesEndpoint = {
  archiveTemplate: jest.fn(),
  createTemplate: jest.fn(),
  deleteTemplate: jest.fn(),
  duplicateTemplate: jest.fn(),
  fileValidation: jest.fn(),
  getTemplate: jest.fn(),
  getTemplates: jest.fn(),
  getValidatedFile: jest.fn(),
  gitValidation: jest.fn(),
  presignedUrl: jest.fn(),
  searchTemplates: jest.fn(),
  templateConditions: jest.fn(),
  updateGitTemplate: jest.fn(),
  updateTemplate: jest.fn(),
  updateTemplateDefaults: jest.fn(),
  validating: jest.fn(),
}
const mockUsersEndpoint = {
  createUser: jest.fn(),
  deleteUser: jest.fn(),
  getUser: jest.fn(),
  getUsers: jest.fn(),
  updateUser: jest.fn(),
}
const mockAccountsEndpoint = {
  getAccounts: jest.fn(),
  updateAccount: jest.fn(),
  createAccount: jest.fn(),
  deleteAccount: jest.fn(),
  getAccount: jest.fn(),
  uploadFont: jest.fn(),
}
const mockRequestsEndpoint = {
  createRequest: jest.fn(),
  getRequests: jest.fn(),
  restartRequest: jest.fn(),
  search: jest.fn(),
}
const mockComposerEndpoint = {
  getCanvas: jest.fn(),
  updateComposer: jest.fn(),
}
const mockPartsEndpoint = {
  createPart: jest.fn(),
  updatePart: jest.fn(),
}
const mockQueueEndpoint = {
  status: jest.fn(),
}
const mockNotificationEndpoint = {
  getNotifications: jest.fn(),
  deleteNotification: jest.fn(),
}

const endpoints = {
  authentication: AuthenticationEndpoint(axios),
  templates: TemplatesEndpoint(axios),
  users: UsersEndpoint(axios),
  accounts: AccountsEndpoint(axios),
  requests: RequestsEndpoint(axios),
  composer: ComposerEndpoint(axios),
  parts: PartsEndpoint(axios),
  queue: QueueEndpoint(axios),
  notification: NotificationEndpoint(axios),
}

test('endpoints module returns expected objects', () => {
  axiosMock.defaults.headers.common['Authorization'] = 'Bearer MockAuthToken'
  axiosMock.defaults.headers.common['X-CSRF-TOKEN'] = 'MockCSRFToken'

  const mockEndpoints = {
    authentication: mockAuthenticationEndpoint,
    templates: mockTemplatesEndpoint,
    users: mockUsersEndpoint,
    accounts: mockAccountsEndpoint,
    requests: mockRequestsEndpoint,
    composer: mockComposerEndpoint,
    parts: mockPartsEndpoint,
    queue: mockQueueEndpoint,
    notification: mockNotificationEndpoint,
  }

  expect(Object.keys(endpoints).sort()).toEqual(Object.keys(mockEndpoints).sort())
})