import React, { useCallback, useEffect, useState } from 'react'
import Heading from 'components/common/Heading/Heading'
import { useTemplateActions } from 'components/context/TemplateContext'
import { useBannerActions } from 'components/context/BannerContext'
import { useNavigate, useParams } from 'react-router-dom'
import ImageComposer from './ImageComposer'
import { useImageComposer, useImageComposerActions } from 'components/context/ImageComposerContext'
import apiClient from 'components/lib/api/apiClient'
import useQueryParams from 'components/common/useQueryParams/useQueryParams'
import { Helmet } from 'react-helmet'

const ComposerPage = () => {
  const params = useParams()
  const queryParams = useQueryParams()
  const { id } = params
  const { findTemplate, updateTemplate, updateParent } =  useTemplateActions()
  const [template, setTemplate] = useState(null)
  const { setBannerError } = useBannerActions()
  const {dirtyCount, setDirtyCount, stateEditor, conditionals } = useImageComposer()
  const { reset } = useImageComposerActions()
  const location = useNavigate()
  const [disabled, setDisabled] = useState(true)
  const save = async () => {
    setDisabled(true)
    const newConditions = conditionals.reduce((acc, condition) => {
      const key = Object.keys(condition)[0]
      const rules = condition[key]?.rules

      if (key !== 'null' && (rules !== null && rules.length > 0)) {
        acc[key] = condition[key]
      }
      return acc
    }, {})
    try {
      await stateEditor.store()
      await apiClient.templates.templateConditions(id, {conditionals: JSON.stringify(newConditions)})
      const templateUpdated = await apiClient.templates.getTemplate(id)
      if(templateUpdated.success) {
        updateTemplate(id, templateUpdated.template)
        if(queryParams?.redirect) updateParent(queryParams.redirect)
      }
      setDirtyCount(0)
    } catch (error) {
      console.error('Error while saving changes:', error)
      if(dirtyCount) setDisabled(false)
    }
  }

  const saveAndFinish = async () => {
    try {
      await save()
      reset()
      if(queryParams?.parentId) location(`/templates/${queryParams?.parentId}/builder`)
      else if(queryParams?.redirect) {
        await updateParent(queryParams.redirect)
        location(`/templates/${queryParams?.redirect}/edit`)
      }
      else location(`/templates/${id}`)
    } catch (error) {
      console.error(error)
    }
  }

  const foundTemplate = useCallback(async () => {
    try {
      const temp = await findTemplate(id)
      if(temp) setTemplate(temp)
      else {
        setBannerError('Template not found')
        location('/')
      }
    } catch (error) {
      console.error(error)
    }
  }, [findTemplate, location, setBannerError, id])

  useEffect(() => {
    if(dirtyCount) setDisabled(false)
    else setDisabled(true)
  }, [dirtyCount])

  useEffect(() => {
    foundTemplate()
  },[foundTemplate])

  return (
    <>
      <Helmet>
        <title>PCP - Composer: {id}</title>
      </Helmet>
      <Heading
        title='Image Composer'
        buttons={[
          { buttonText: dirtyCount > 0 ? `Save ${dirtyCount}` : 'Save', onClick: save, classes: 'save_changes', disabled: disabled },
          { buttonText: 'Save & Finish', onClick: saveAndFinish, classes: 'save_btn' }
        ]}
      />
      <hr />
      {template && <ImageComposer
        template={template}
        />
      }
    </>
  )
}

export default ComposerPage