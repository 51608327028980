import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTemplateActions } from 'components/context/TemplateContext'
import { useBannerActions } from 'components/context/BannerContext'
import useQueryParams from 'components/common/useQueryParams/useQueryParams'
import SinchComposer from 'sinch-composer'
import cookies from 'components/util/cookies/url/cookies'
const ComposerPageV2 = () => {
  const params = useParams()
  const queryParams = useQueryParams()
  const { id } = params
  const { findTemplate, updateParent} =  useTemplateActions()
  const [template, setTemplate] = useState(null)
  const { setBannerError } = useBannerActions()
  const location = useNavigate()
  const authToken = cookies.authToken.get()
  const foundTemplate = useCallback(async () => {
    try {
      const temp = await findTemplate(id)
      if(temp) setTemplate(temp)
      else {
        setBannerError('Template not found')
        location('/')
      }
    } catch (error) {
      console.error(error)
    }
  }, [findTemplate, location, setBannerError, id])

  const redirectFn = async () => {
    if(queryParams?.parentId) location(`/templates/${queryParams?.parentId}/builder`)
    else if(queryParams?.redirect) {
      await updateParent(queryParams.redirect)
      location(`/templates/${queryParams?.redirect}/edit`)
    }
    else location(`/templates/${id}`)
  }

  useEffect(() => {
    foundTemplate()
  },[foundTemplate])
  if(!template) return <p>no template</p>

  return (
    <>
      <SinchComposer
        templateId={id}
        loadURL={window.location.origin}
        token={authToken}
        redirectFn={redirectFn}
      />
    </>
  )
}

export default ComposerPageV2