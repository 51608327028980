import React, { useEffect, useState } from 'react'
import { QueryBuilderAntD } from '@react-querybuilder/antd'
import { QueryBuilder } from 'react-querybuilder'
import { useImageComposer } from 'components/context/ImageComposerContext'
import { useVariables } from 'components/context/VariableContext'

const Conditionals = ({template}) => {
  const {
    removedId,
    setRemovedId,
    setDirtyCount,
    conditionId,
    conditionals,
    setConditionals,
    setConditionId,
  } = useImageComposer()
  const [query, setQuery] = useState({ combinator: 'and', rules: [] })
  const [variables, setVariables] = useState([{ name: 'none', label: 'none' }])
  const { exportVars } = useVariables()
  const handleSaveValidation = (q) => {
    const existingValidationIndex = conditionals.findIndex((condition) =>
      condition[conditionId]
    )

    if (existingValidationIndex !== -1) {
      const updatedValidations = [...conditionals]
      updatedValidations[existingValidationIndex][conditionId] = q
      setConditionals(updatedValidations)
    } else {
      setConditionals([...conditionals, { [conditionId]: q }])
    }
    if(conditionId) setDirtyCount((prevCount) => prevCount + 1)
  }

  useEffect(() => {
    if (template){
      const { optional_variables, options, required_variables } = template
      const newSet = new Set([...required_variables, ...optional_variables, ...exportVars])
      const allVars = [...newSet]
      let fields = []
      for (const value of allVars) {
        fields = [...fields, { name: value, label: value }]
      }
      setVariables(fields || [])
      fields = []
      if(options?.conditionals){
        const keys = Object.keys(options?.conditionals)
        for (const value of keys) {
          fields = [...fields, value]
        }
        const arrayOfObjects = Object.entries(options?.conditionals).map(([key, value]) => {
          const transformedValue = {[key]: value}
          return transformedValue
        })
        setConditionals(arrayOfObjects)
      }
    }
  }, [template, setConditionals, exportVars])

  useEffect(() => {
    if(removedId){
      setConditionals((prevCondition) => {
        return prevCondition.filter((rule) => {
          return !rule[conditionId]
        })
      })
      setRemovedId(null)
      setConditionId(null)
    }
  }, [removedId, conditionId, setRemovedId, setConditionId, setConditionals])

  useEffect(() => {
    if(conditionId){
      const foundCondition = conditionals.find(val => val[conditionId])
      setQuery(((foundCondition && foundCondition[conditionId]) || { combinator: 'and', rules: [] }))
    }
  },[conditionId, conditionals])

  return (
    <QueryBuilderAntD>
      <QueryBuilder fields={variables} query={query} onQueryChange={(q) => {
        handleSaveValidation(q)
        setQuery(q)
      }} />
  </QueryBuilderAntD>
  )
}

export default Conditionals